<template>
  <div class="activitylist">
    <van-search
      v-model="keywords"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
      shape="round"
    >
      <template #left>
        <van-icon name="arrow-left" @click="onClickLeft" />
      </template>
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div class="main">
      <van-tabs v-model="active" @click="TabonClick">
        <van-tab
          v-for="(item, index) in activityList"
          :key="index"
          :title="item.title"
        >
          <van-list
            v-model="loading"
            :finished="item.finished"
            finished-text="没有更多了"
            :immediate-check="false"
            @load="getlist"
          >
            <div v-if="!item.nodata">
              <van-cell
                :border="false"
                v-for="(val, key) in item.list"
                :key="key"
                :to="{
                  name: 'activityDetail',
                  query: {
                    shareid: shareid,
                    merchid: merchid,
                    id: val.id
                  }
                }"
              >
                <template #title>
                  <div class="title">
                    <div class="row1">
                      <van-image
                        :src="val.logo"
                        width="60px"
                        height="60px"
                        lazy-load
                      >
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                      </van-image>
                      <div class="title_word">
                        <div class="title_text">
                          <div class="title_value van-ellipsis">{{val.title}}</div>
                          <van-button size="small" type="primary" @click.stop="showInput(key)">核销单号</van-button>
                        </div>
                        <div class="time">
                          <van-count-down v-if="val.stoptime > 0" :time="val.stoptime" format="距结束：DD天HH时mm分ss秒" />
                          <div v-if="val.stoptime <= 0">活动已经结束</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #label>
                  <div>{{val.description}}</div>
                </template>
              </van-cell>
            </div>
            <div v-if="item.nodata">
              <van-empty description="暂无活动" />
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
    <van-dialog
      v-model="showInputOrdersn"
      title="标题"
      show-cancel-button
      :before-close="beforeClase"
    >
      <div class="inputMain">
        <van-field
          v-model="ordersn"
          label="旗舰店订单号"
          required
          placeholder="请输入旗舰店订单号"
        />
        <van-field
          v-model="agentcode"
          label="推荐人邀请码"
          :required="noAgentcode == '1' ? true : false"
          placeholder="请输入推荐人邀请码"
        />
        <van-radio-group v-model="noAgentcode" direction="horizontal">
          <van-radio name="1" checked-color="#EE0A24" icon-size="14px">有推荐人</van-radio>
          <van-radio name="2" checked-color="#EE0A24" icon-size="14px">无推荐人</van-radio>
        </van-radio-group>
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: "activityList",
  data() {
    return {
      shareid: 0,
      merchid: 0,
      activityList: [],
      active: 1,
      size: 20,
      loading: false,
      showInputOrdersn: false, // 是否显示核销单号输入框
      ordersn: '', // 用户输入的订单号
      agentcode: '', // 用户输入的邀请码
      id: 0, // 用户点击的活动id
      listIndex: -1, // 用户点击的第几个活动 从0开始
      keywords: '',
      noAgentcode: '1',
      my: 0,
    }
  },
  mounted() {
    this.my = this.$route.query.my ? this.$route.query.my : 0;
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.shareid) {
        this.shareid = this.$route.query.shareid
      }else if (localStorage.getItem("DK_MEMBERID")) {
        this.shareid = localStorage.getItem("DK_MEMBERID");
      }
      this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
      this.$parent.getmerchname(this.merchid, "活动列表");
      this.getTitle();
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 获取活动标题
    getTitle() {
      let that = null;
      that = this;
      that.$toast({
        type: 'loading',
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "web/activity/get_title",
          that.$qs.stringify({
            my: that.my
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.activityList = res.data.data;
            that.getlist();
          }
        })
        .catch(err => {
          console.log(err);
          that.$toast.clear();
        })
    },
    // 获取活动列表
    getlist() {
      console.log("getlist");
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "web/activity/getlist",
          that.$qs.stringify({
            offset: that.activityList[that.active].offset,
            size: that.size,
            keywords: that.keywords,
            status: that.active,
            my: that.my
          })
        )
        .then(res => {
          console.log(res);
          that.$toast.clear();
          that.loading = false;
          let index = -1;
          let offset = -1;
          index = that.active;
          offset = that.activityList[index].offset;
          that.activityList[index].finished = false;
          that.activityList[index].nodata = false;
          if (res.data.code == 100000) {
            if (offset == 0) {
              that.activityList[index].list = res.data.data;
            } else {
              res.data.data.forEach(item => {
                that.activityList[index].list.push(item);
              })
            }
            if (res.data.data.length < that.size) {
              that.activityList[index].finished = true;
            }
          }else {
            that.activityList[index].finished = false;
            if (offset == 0) {
              that.activityList[index].nodata = true;
            }
          }
        })
    },
    // 点击标题栏
    TabonClick(name) {
      this.active = name;
      this.activityList[name].offset = 0;
      this.activityList[name].list = [];
      this.getlist();
    },
    // 点击核销单号按钮
    showInput(index) {
      this.listIndex = index;
      this.id = this.activityList[this.active].list[index].id;
      // this.ordersn = "";
      // this.agentcode = "";
      // this.showInputOrdersn = true;
      this.$router.push({
        name: "hexiaoOrder",
        query: {
          id: this.id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      });
    },
    // 弹框关闭前回调
    beforeClase(action, done) {
      console.log(action);
      let that = null;
      that = this;
      if (action == 'confirm') {
        // this.ordersnconfirm();
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "web/activity/addordersn",
            that.$qs.stringify({
              id: that.id,
              ordersn: that.ordersn,
              agentcode: that.agentcode,
              noAgentcode: that.noAgentcode
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              that.$toast(res.data.msg ? res.data.msg : '保存成功');
              done();
            }else {
              that.$toast(res.data.msg ? res.data.msg : '保存失败');
              done(false);
            }
          })
      }else {
        done();
      }
    },

    // 点击搜索按钮
    onSearch() {
      this.activityList[this.active].offset = 0;
      this.activityList[this.active].list = [];
      this.getlist();
    },
  },
}
</script>
<style lang="less">
  .activitylist {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    background-color: #f0f0f0;
    .van-search {
      padding-left: 0;
      .van-icon-arrow-left {
        padding: 0 16px;
      }
      .van-search__action {
        padding: 0 16px;
      }
      .van-cell__value {
        flex: 1;
      }
    }
    .main {
      padding-bottom: 50px;
      .van-cell {
        width: auto;
        overflow: hidden;
        padding: 10px;
        margin: 10px;
        border-radius: 10px;
        .van-cell__title {
          flex: 1;
          overflow: hidden;
          text-align: left;
          .row1 {
            display: flex;
            .van-image {
              display: block;
              border-radius: 4px;
              margin-right: 10px;
              overflow: hidden;
            }
            .title_word {
              flex: 1;
              .title_text {
                display: flex;
                overflow: hidden;
                .title_value {
                  flex: 1;
                  overflow: hidden;
                  margin-right: 5px;
                }
              }
              .time {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
    .inputMain {
      padding: 10px;
      overflow: hidden;
      .van-cell__value {
        flex: 1;
      }
      .van-radio-group {
        width: 100%;
        margin-top: 10px;
        .van-radio {
          flex: 1;
          justify-content: center;
        }
        .van-radio--horizontal {
          margin-right: 0;
        }
        .van-radio__label {
          font-size: 14px;
        }
      }
    }
  }
</style>